import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import ProfileRoutes from "containers/admin/clients/client/tab-components/profile-tab/profile-routes";
import CampaignsRoutes from "containers/admin/clients/client/tab-components/campaigns-tab/campaigns-routes";
import Theme from "containers/admin/clients/client/tab-components/themes-tab/theme/theme";

import PageContent from "components/partials/page-content/page-content";
import ClientSecondaryNav from "containers/admin/clients/client/components/client-secondary-nav/client-secondary-nav";

import { usePagePermission } from "hooks/use-page-permission";
import { Route as AppRoute } from "utilities/app-routes";

import { Permission } from "types/auth";

const ClientRoutes = () => {
  let { path, url } = useRouteMatch();

  usePagePermission(Permission.PERM_CLIENT_READ);

  return (
    <Switch>
      <Redirect exact from={path} to={path + AppRoute.campaigns} />
      <Route path={path + AppRoute.campaigns}>
        <CampaignsRoutes parentUrl={url} />
      </Route>
      <Route path={path + AppRoute.theme}>
        <ClientSecondaryNav parentUrl={url} />
        <PageContent data-testid="theme-page">
          <Theme baseThemeUrl={url} />
        </PageContent>
      </Route>
      <Route path={path + AppRoute.profile}>
        <ClientSecondaryNav parentUrl={url} />
        <PageContent data-testid="profile-routes">
          <ProfileRoutes />
        </PageContent>
      </Route>
    </Switch>
  );
};

export default ClientRoutes;
