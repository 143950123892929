import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Color from "containers/admin/clients/client/tab-components/themes-tab/theme/preview/sub-tabs/color/color";
import Fonts from "containers/admin/clients/client/tab-components/themes-tab/theme/preview/sub-tabs/fonts/fonts";
import Typography from "containers/admin/clients/client/tab-components/themes-tab/theme/preview/sub-tabs/typography/typography";
import Button from "containers/admin/clients/client/tab-components/themes-tab/theme/preview/sub-tabs/button/button";

import { PreviewRoute } from "utilities/app-routes";

const PreviewRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={path + PreviewRoute.color} />
      <Route path={path + PreviewRoute.color}>
        <Color />
      </Route>
      <Route path={path + PreviewRoute.fonts}>
        <Fonts />
      </Route>
      <Route path={path + PreviewRoute.typography}>
        <Typography />
      </Route>
      <Route path={path + PreviewRoute.button}>
        <Button />
      </Route>
    </Switch>
  );
};

export default PreviewRoutes;
