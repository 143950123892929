import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import uniqid from "uniqid";

import Button from "components/forms/button/button";
import Select from "components/forms/select/select";
import { Span } from "components/partials/typography/typography";
import {
  TableEmptyMessage,
  TableLoadingMessage,
} from "components/partials/paginated-grid/paginated-grid";
import { TableHeader, ITableColumn } from "components/table/table-header/table-header";
import { Table, TableBody, TableHead, TableProps } from "components/table/table";

import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  emptyPaginatedResponse,
  PaginatedResponse,
  PaginatedRequestOptions,
} from "types/pagination";

interface PaginatedTableProps<T, ColumnId> extends TableProps {
  renderRow: (rowData: T) => JSX.Element;
  headers: ITableColumn<ColumnId>[] | string[];
  fetchPage: (options: PaginatedRequestOptions) => Promise<PaginatedResponse<T>>;
  shouldRefresh?: boolean;
  setShouldRefresh?: (shouldRefresh: boolean) => void;
  currentSortOrder?: string;
  handleTableSort?: (columnId: ColumnId) => void;
}

function PaginatedTable<T, ColumnId>(tableProps: PaginatedTableProps<T, ColumnId>) {
  const paginationState = usePaginationState();
  const [response, setResponse] = useState<PaginatedResponse<T>>(emptyPaginatedResponse);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    fetchPage: __fetchPage__,
    shouldRefresh,
    setShouldRefresh,
    currentSortOrder = "",
    handleTableSort = () => {},
    renderRow,
    headers,
    ...rest
  } = tableProps;

  const fetchPage = useCallback(
    (paginationOptions: PaginatedRequestOptions) => {
      setIsLoading(true);
      __fetchPage__(paginationOptions)
        .then((res: PaginatedResponse<T>) => {
          setResponse(res);
          setErrorMessage(undefined);
        })
        .catch((err: Error) => {
          if (err.message) {
            setErrorMessage(err.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
          setShouldRefresh && setShouldRefresh(false);
        });
    },
    [__fetchPage__, setShouldRefresh],
  );

  const prevPaginationStateRef = useRef<PaginatedRequestOptions | null>(null);

  useEffect(() => {
    const prevPaginationState = prevPaginationStateRef.current;

    if (
      shouldRefresh ||
      !prevPaginationState ||
      paginationState.page !== prevPaginationState.page ||
      paginationState.size !== prevPaginationState.size
    ) {
      fetchPage(paginationState);
      setShouldRefresh && setShouldRefresh(false);
    }

    prevPaginationStateRef.current = paginationState;
  }, [paginationState, shouldRefresh, fetchPage, setShouldRefresh]);

  const renderTableContent = () => {
    if (isLoading) {
      return <TableLoadingMessage />;
    }

    if (response.totalItems === 0) {
      return (
        <Box>
          {errorMessage ? (
            <Box ml="4" mt="2">
              <LoadErrorMessage
                fetchPage={fetchPage}
                paginationOptions={paginationState}
                setError={setErrorMessage}
              />
              <Span>{!!errorMessage && errorMessage}</Span>
            </Box>
          ) : (
            <TableEmptyMessage />
          )}
        </Box>
      );
    }

    return (
      <Table {...rest}>
        <TableHead>
          {tableProps.headers.map((header) => (
            <TableHeader
              key={uniqid()}
              column={header}
              sortBy={currentSortOrder.split(",")}
              handleTableSort={handleTableSort}
            />
          ))}
        </TableHead>

        <TableBody>
          {response.items.map((item) => (
            <React.Fragment key={JSON.stringify(item)}>{tableProps.renderRow(item)}</React.Fragment>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box data-testid="paginated-table">
      <PaginationRange response={response} />
      {renderTableContent()}
      <PaginationButtons response={response} paginationState={paginationState} />
    </Box>
  );
}

export default PaginatedTable;
export interface PaginationState extends PaginatedRequestOptions {
  setPage: (page: number) => void;
  setSize: (size: number) => void;
}

export function usePaginationState(): PaginationState {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const initialPage =
    parseInt(query.get("page") || `${DEFAULT_PAGINATED_REQUEST_OPTIONS.page! + 1}`) - 1;
  const initialSize = parseInt(query.get("size") || `${DEFAULT_PAGINATED_REQUEST_OPTIONS.size}`);

  const [paginationState, setPaginationState] = useState<PaginatedRequestOptions>({
    page: initialPage,
    size: initialSize,
  });

  const setPage = useCallback(
    (page: number) => {
      setPaginationState((prev) => ({ ...prev, page }));

      setTimeout(() => {
        query.set("page", (page + 1).toString());
        history.push({ search: query.toString() });
      }, 0);
    },
    [history, query],
  );

  const setSize = useCallback(
    (size: number) => {
      setPaginationState((prev) => ({ ...prev, size }));

      setTimeout(() => {
        query.set("size", size.toString());
        history.push({ search: query.toString() });
      }, 0);
    },
    [history, query],
  );
  useEffect(() => {
    const currentPageNumber = query.get("page") || `${DEFAULT_PAGINATED_REQUEST_OPTIONS.page + 1}`;
    const newPageNumber = parseInt(currentPageNumber) - 1;
    const newPageSize = parseInt(query.get("size") || `${DEFAULT_PAGINATED_REQUEST_OPTIONS.size}`);

    setPaginationState({ page: newPageNumber, size: newPageSize });
  }, [location.search, query]);

  return useMemo(
    () => ({ ...paginationState, setPage, setSize }),
    [paginationState, setPage, setSize],
  );
}

export function PaginationRange<T>(props: { response: PaginatedResponse<T> }) {
  const { page, size, totalPages, totalItems } = props.response;

  const noResults = totalItems === 0;
  const startingPage = page * size;
  const isLastPage = page === totalPages - 1;

  const [start, end] = (() => {
    if (noResults) {
      return [0, 0];
    }

    if (isLastPage) {
      return [startingPage + 1, totalItems];
    }

    return [startingPage + 1, startingPage + size];
  })();

  return (
    <NowShowing start={start} end={end} totalItems={totalItems}>
      {/* TODO: Hook this up */}
      {/* <TableDownloadButton aria-label="Download Clients"/> */}
    </NowShowing>
  );
}

interface NowShowingProps {
  start: number;
  end: number;
  totalItems: number;
  children?: React.ReactNode;
}
export const NowShowing = ({ start, end, totalItems, children }: NowShowingProps) => (
  <Flex
    bg="transparent"
    paddingTop={4}
    paddingBottom={2}
    fontSize="sm"
    fontWeight="bold"
    align="center"
    justify="space-between">
    <Span>
      Showing {start} - {end} of {totalItems} items
    </Span>
    {children && <Box>{children}</Box>}
  </Flex>
);

export function LoadErrorMessage(props: {
  fetchPage: any;
  paginationOptions: PaginatedRequestOptions;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const retry = () => {
    props.setError(undefined);
    props.fetchPage(props.paginationOptions);
  };

  return (
    <Flex
      flexDirection="column"
      height="10rem"
      fontSize="xl"
      justifyContent="center"
      alignItems="center">
      <Span mb="2">Failed to load data</Span>
      <Button onClick={retry}>Retry</Button>
    </Flex>
  );
}

export function PaginationButtons<T>(props: {
  response: PaginatedResponse<T>;
  paginationState: PaginationState;
}) {
  const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];

  /** Determines the largest available option. Example:
   * - If there are 4 items, the largest option is 5.
   * - If there are 6 items, the largest option is 10. */
  const maxSizeOption =
    PAGE_SIZE_OPTIONS.find((size) => size >= props.response.totalItems) ||
    PAGE_SIZE_OPTIONS[PAGE_SIZE_OPTIONS.length - 1];

  // always show first and last. show previous if not on first. show next if not on last.
  // otherwise, ellipsis range of pages like so:
  //   1 2 3 4 5     | show 'em all
  //   1..3 4 5 6    | show with just an ellipsis on the left
  //   1 2 3 4..6    | show with just an ellipsis on the right
  //   1..3 4 5..7   | show with both ellipsis-ies
  //
  const [pages, showEllipsisLeft, showEllipsisRight] = ((
    page,
    totalPages,
  ): [number[], boolean, boolean] => {
    if (totalPages === 0) {
      return [[], false, false];
    }

    if (totalPages <= 5) {
      return [Array.from({ length: totalPages }).map((_, i) => i), false, false];
    }

    let pages = Array.from(
      new Set([0, Math.max(0, page - 1), page, Math.min(page + 1, totalPages - 1), totalPages - 1]),
    );

    return [pages, pages[1] > 1, pages[pages.length - 2] < totalPages - 2];
  })(props.response.page, props.response.totalPages);

  const PaginationButton = ({ ...rest }) => (
    <Button size={"sm"} variant="tertiary" maxW={10} _active={{ color: "black" }} {...rest} />
  );

  return (
    <Flex data-testid="paginated-buttons" justifyContent="space-between" px={4}>
      <Flex as={FormControl} width="auto" align="center">
        <FormLabel fontWeight="bold" fontSize="xs" mb="0" marginInlineEnd={2}>
          View
        </FormLabel>
        <Select
          value={`${props.paginationState.size} per page`}
          variant="outline"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            props.paginationState.setSize(parseInt(e.target.value));
          }}>
          {PAGE_SIZE_OPTIONS.map((size) => (
            // disable if `size` is greater than `totalItems`
            <option key={size} disabled={size > maxSizeOption}>{`${size} per page`}</option>
          ))}
        </Select>
      </Flex>

      <HStack spacing="4">
        {props.response.page > 0 && (
          <PaginationButton
            data-testid="pagination-previous"
            onClick={() => props.paginationState.setPage(props.response.page - 1)}>
            <ChevronLeftIcon />
          </PaginationButton>
        )}

        {pages.map((page, i) => (
          <React.Fragment key={page || i}>
            {i === pages.length - 1 && showEllipsisRight && (
              <Span data-testid="pagination-ellipsis-right">&hellip;</Span>
            )}

            <PaginationButton
              data-testid={`pagination-button-${page}`}
              isActive={page === props.response.page}
              onClick={() => props.paginationState.setPage(page)}>
              {page + 1} {/* pages are zero-based indexed, so shift it for the humans */}
            </PaginationButton>

            {i === 0 && showEllipsisLeft && (
              <Span data-testid="pagination-ellipsis-left">&hellip;</Span>
            )}
          </React.Fragment>
        ))}

        {props.response.page < props.response.totalPages - 1 && (
          <PaginationButton
            data-testid="pagination-next"
            onClick={() => props.paginationState.setPage(props.response.page + 1)}>
            <ChevronRightIcon />
          </PaginationButton>
        )}
      </HStack>
      <Button
        cursor="default"
        variant="link"
        fontWeight="normal"
        fontSize="sm"
        textDecoration="underline">
        {/* <ChevronUpIcon />
        TODO - This is currently an invisible button with default cursor. Keeping it for flex needs implementation */}
      </Button>
    </Flex>
  );
}
