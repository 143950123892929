import omit from "lodash.omit";
import { Base64 } from "js-base64";

import Model from "models/model";

import { generatePaginatedQueryString } from "utilities";

import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  PaginatedRequestOptions,
  PaginatedResponse,
} from "types/pagination";
import { ThemeAttributes } from "types/theme";
import { StatusString, TypekitResponse } from "types/index";

/**
 * Encode Theme Payload
 * encode any attributes that will include any text or characters that will trigger a firewall rule
 *s
 * @param attributes attributes to be encoded
 */
const encodeThemePayload = (attributes: Partial<ThemeAttributes>) => {
  const payload: any = { ...attributes };

  const propsToEncode = [
    "bodyFontFamily",
    "h1FontFamily",
    "h2FontFamily",
    "h3FontFamily",
    "inputTextFontFamily",
    "fontPresets",
  ];

  propsToEncode.forEach((prop) => {
    const propKey = prop as keyof Partial<ThemeAttributes>;
    if (payload[propKey] && prop !== "fontPresets") {
      payload[prop] = Base64.encode(payload[propKey]);
    } else if (payload[propKey] && prop === "fontPresets") {
      payload[prop] = payload[prop].map((_: string) => Base64.encode(_));
    }
  });

  return payload;
};

class Theme extends Model<ThemeAttributes> implements ThemeAttributes {
  static all({
    options = DEFAULT_PAGINATED_REQUEST_OPTIONS,
    clientId,
    status,
  }: {
    options?: PaginatedRequestOptions;
    clientId: string;
    status?: string;
  }): Promise<PaginatedResponse<Theme>> {
    let queryString = generatePaginatedQueryString(options);
    if (status) {
      queryString += `&status=${status}`;
    }
    return Theme.connection.get(`clients/${clientId}/themes${queryString}`).then((response) => {
      const items = response.data.content
        ? response.data.content.map((attributes: ThemeAttributes) => new Theme(attributes))
        : [];
      return {
        items: items,
        totalItems: response.data.totalElements,
        size: response.data.size,
        page: response.data.number,
        totalPages: response.data.totalPages,
        last: response.data.last,
      };
    });
  }

  static find({ clientId, id }: { clientId: string; id: string }): Promise<Theme> {
    return this.connection
      .get(`clients/${clientId}/themes${id ? `/${id}` : ""}`)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static create(attributes: {
    clientId: string;
    name: string;
    description: string;
    status: StatusString;
  }): Promise<Theme> {
    return this.connection
      .post(`clients/${attributes.clientId}/themes`, attributes)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static update({
    clientId,
    id,
    attributes,
  }: {
    clientId: string;
    id: string;
    attributes: Partial<ThemeAttributes>;
  }): Promise<Theme> {
    // Remove lastModifiedDate before PATCH
    const updateAttributes = omit(attributes, ["lastModifiedDate", "status", "client"]);

    return this.connection
      .patch(`clients/${clientId}/themes/${id}`, encodeThemePayload(updateAttributes))
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static replace({
    clientId,
    id,
    attributes,
  }: {
    clientId: string;
    id: string;
    attributes: ThemeAttributes;
  }): Promise<Theme> {
    // Remove lastModifiedDate before PATCH
    const updateAttributes = omit(attributes, ["lastModifiedDate", "client"]);
    return this.connection
      .put(`clients/${clientId}/themes/${id}`, encodeThemePayload(updateAttributes))
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static claimLock({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .put(`clients/${clientId}/themes/${id}/claim-lock`, undefined)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static releaseLock({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .put(`clients/${clientId}/themes/${id}/release-lock`, undefined)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static clone({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .post(`clients/${clientId}/themes/${id}/clone`, undefined)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static publish({ id, clientId }: { id: string; clientId: string }): Promise<Theme> {
    return this.connection.put(`clients/${clientId}/themes/${id}/publish`, {}).then((response) => {
      return new Theme(response.data);
    });
  }

  static delete({ clientId, id }: { clientId: string; id: string }): Promise<void> {
    return this.connection.delete(`clients/${clientId}/themes/${id}`).then();
  }

  static versionHistory({
    clientId,
    id,
    options = DEFAULT_PAGINATED_REQUEST_OPTIONS,
  }: {
    clientId: string;
    id: string;
    options: PaginatedRequestOptions;
  }): Promise<PaginatedResponse<Theme>> {
    const queryString = generatePaginatedQueryString(options);
    return Theme.connection
      .get(`/clients/${clientId}/themes/${id}/version-history${queryString}`)
      .then((response) => {
        return {
          items: response.data.content.map((attributes: ThemeAttributes) => new Theme(attributes)),
          totalItems: response.data.totalElements,
          size: response.data.size,
          page: response.data.number,
          totalPages: response.data.totalPages,
          last: response.data.last,
        };
      });
  }

  static archive({ clientId, id }: { clientId: string; id: string }) {
    return Theme.connection
      .put(`/clients/${clientId}/themes/${id}/archive`, {})
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static createDraft({
    clientId,
    id,
    version,
    versionNotes,
  }: {
    clientId: string;
    id: string;
    version: string;
    versionNotes: string;
  }) {
    return Theme.connection
      .put(`/clients/${clientId}/themes/${id}/create-draft`, {
        version: version,
        versionNotes: versionNotes,
      })
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static upload({ clientId, file }: { clientId: string; file: any }) {
    return Theme.connection
      .post(`/clients/${clientId}/theme-assets?type=image`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static nextVersions({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .get(`clients/${clientId}/themes/${id}/next-versions`)
      .then((response) => {
        return response;
      });
  }

  static editVersionDetails({
    clientId,
    id,
    version,
    versionNotes,
  }: {
    clientId: string;
    id: string;
    version: string;
    versionNotes: string;
  }) {
    return this.connection
      .put(`clients/${clientId}/themes/${id}/version-details`, {
        version: version,
        versionNotes: versionNotes,
      })
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static getTypekitFonts({
    clientId,
    projectId,
  }: {
    clientId: string;
    projectId: string;
  }): Promise<TypekitResponse> {
    return this.connection
      .get(`clients/${clientId}/themes/typekit/${projectId}`)
      .then((response) => {
        return response.data;
      });
  }

  // --------- behavior (abstract implementations or custom) --------
  get attributes(): ThemeAttributes {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      version: this.version,
      versionNotes: this.versionNotes,
      draftedFromVersion: this.draftedFromVersion,
      typekitId: this.typekitId,
      lockedBy: this.lockedBy,
      publishedAt: this.publishedAt,
      lastModifiedDate: this.lastModifiedDate,
      createdDate: this.createdDate,
      rootThemeId: this.rootThemeId,
      client: this.client,
      typekitFonts: this.typekitFonts,
      colors: this.colors,
      fonts: this.fonts,
      typography: this.typography,
      button: this.button,
    };
  }

  // -------- proxies to attributes ---------
  get id() {
    return this._attributes["id"];
  }

  get name() {
    return this._attributes["name"];
  }

  get description() {
    return this._attributes["description"];
  }

  get status() {
    return this._attributes["status"];
  }

  get draftedFromVersion() {
    return this._attributes["draftedFromVersion"];
  }

  get version() {
    return this._attributes["version"];
  }

  get versionNotes() {
    return this._attributes["versionNotes"];
  }

  get typekitId() {
    return this._attributes["typekitId"];
  }

  get lockedBy() {
    return this._attributes["lockedBy"];
  }

  get publishedAt() {
    return this._attributes["publishedAt"];
  }

  get lastModifiedDate() {
    return this._attributes["lastModifiedDate"];
  }

  get createdDate() {
    return this._attributes["createdDate"];
  }

  get rootThemeId() {
    return this._attributes["rootThemeId"];
  }

  get client() {
    return this._attributes["client"];
  }

  get typekitFonts() {
    return this._attributes["typekitFonts"];
  }

  get colors() {
    return this._attributes["colors"];
  }

  get fonts() {
    return this._attributes["fonts"];
  }

  get typography() {
    return this._attributes["typography"];
  }

  get button() {
    return this._attributes["button"];
  }
}

export { Theme as default };
