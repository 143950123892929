import { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import PreviewRoutes from "containers/admin/clients/client/tab-components/themes-tab/theme/preview/preview-routes";

import { getThemePreviewTabRoute } from "containers/admin/clients/client/tab-components/themes-tab/theme/theme.helper";

const Preview = () => {
  const { url } = useRouteMatch();

  const history = useHistory();
  const location = useLocation();

  const [currentTab, setCurrentTab] = useState<string>("Preview");

  const tabs = [
    { name: "Color", path: "color" },
    { name: "Fonts", path: "fonts" },
    { name: "Typography", path: "typography" },
    { name: "Button", path: "button" },
  ];

  const getTabFromPath = () => {
    const path = location.pathname;
    const matchedTab = tabs.find((tab) => path.includes(tab.path))?.name;
    return matchedTab || "Color";
  };

  useEffect(() => {
    const activeTab = getTabFromPath();
    setCurrentTab(activeTab);
    // eslint-disable-next-line
  }, [location.pathname]);

  const changeTab = (str: string) => {
    const tab = getThemePreviewTabRoute(str, url);

    history.push(tab);
  };

  return (
    <div>
      <ToggleButtonGroup
        groupName="theme-preview-tabs-toggle"
        options={["Color", "Fonts", "Typography", "Button"]}
        onChange={(option) => changeTab(option)}
        value={currentTab}
        mb={3}
      />
      <PreviewRoutes />
    </div>
  );
};

export default Preview;
