import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Preview from "containers/admin/clients/client/tab-components/themes-tab/theme/preview/preview";

import { Route as AppRoute } from "utilities/app-routes";

const ThemeRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={path + AppRoute.preview} />
      <Route path={path + AppRoute.preview}>
        <Preview />
      </Route>
      <Route path={path + AppRoute.details}>Details</Route>
      <Route path={path + AppRoute.versions}>Version History</Route>
    </Switch>
  );
};

export default ThemeRoutes;
