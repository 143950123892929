import { useState, useRef, useMemo } from "react";

import CustomTextInput from "components/new/custom-text-input/custom-text-input";

import { debounce } from "helpers/debounce";

import Icons from "assets";

const Search = ({
  setSearch,
  customClass,
  placeholder = "Search...",
  customClassIcon = "",
}: {
  setSearch: (updatedValue: string) => void;
  customClass?: string;
  placeholder?: string;
  customClassIcon?: string;
}) => {
  const [searchIsStarted, setSearchIsStarted] = useState(false);

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleOnChange = useMemo(
    () =>
      debounce((value: string) => {
        setSearch(value);
      }, 300),
    [setSearch],
  );
  const iconCommonClass = `text-aqua-dark absolute left-4 ${customClassIcon}`;

  return (
    <div className={`flex items-center relative ${customClass}`}>
      {searchIsStarted ? (
        <Icons.Plus
          alt="CloseIcon"
          height="100%"
          width={16}
          customClass={` ${iconCommonClass} rotate-45 cursor-pointer`}
          onClick={() => {
            ref.current.value = "";
            setSearchIsStarted(false);
            setSearch("");
          }}
        />
      ) : (
        <Icons.Search alt="SearchIcon" width={16} customClass={iconCommonClass} />
      )}
      <CustomTextInput
        onChange={(e) => {
          setSearchIsStarted(!!e.target?.value);
          handleOnChange(e.target?.value.trim());
        }}
        onFocus={(e) => setSearchIsStarted(!!e?.target?.value)}
        inputCustomClass="border-gray-05 pl-11"
        inputWrapperCustomClass="!m-0"
        placeholder={placeholder}
        ref={ref}
      />
    </div>
  );
};

export default Search;
