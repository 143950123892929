import { Menu, MenuList, MenuListProps, useMenuContext } from "@chakra-ui/react";
import MenuItemButton, {
  MenuItemButtonProps,
} from "components/partials/menu-item-button/menu-item-button";

interface ActionsMenuProps extends MenuListProps {
  trigger: React.ReactNode;
}

export const ActionsMenu = ({ children, trigger, ...props }: ActionsMenuProps) => (
  <Menu {...props}>
    {trigger}
    <MenuList zIndex="dropdown" className="!p-1 !shadow-sm" {...props}>
      {children}
    </MenuList>
  </Menu>
);

export const ActionsMenuItem = {
  Root: MenuItemButton,

  CloneTouchpoint: ({ onClick }: MenuItemButtonProps) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="CopyDocument">
        Clone touchpoint
      </MenuItemButton>
    );
  },

  EditVersionDetails: ({ onClick }: MenuItemButtonProps) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="Document">
        Edit version details
      </MenuItemButton>
    );
  },

  EditTouchpoint: ({ onClick, isEmail }: MenuItemButtonProps & { isEmail: boolean }) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="Edit">
        {isEmail ? "Edit email" : "Edit landing page"}
      </MenuItemButton>
    );
  },

  PreviewTouchpoint: ({ onClick, isEmail }: MenuItemButtonProps & { isEmail: boolean }) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="EyeVisible">
        {isEmail ? "Preview email" : "Preview landing page"}
      </MenuItemButton>
    );
  },

  CreateDraftFromVersion: ({ onClick }: MenuItemButtonProps) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="NewDocument">
        Create draft from this version
      </MenuItemButton>
    );
  },

  SendToClient: ({ onClick }: MenuItemButtonProps) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="Airplane">
        Send to Client
      </MenuItemButton>
    );
  },

  Publish: ({ onClick, isEmail }: MenuItemButtonProps & { isEmail: boolean }) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="Upload">
        {isEmail ? "Publish to Iterable" : "Publish"}
      </MenuItemButton>
    );
  },

  ArchiveDraft: ({ onClick }: MenuItemButtonProps) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="Archive">
        Archive draft
      </MenuItemButton>
    );
  },

  ApproveAsClient: ({ onClick }: MenuItemButtonProps) => {
    const { onClose } = useMenuContext();
    return (
      <MenuItemButton onClick={onClick} onAction={onClose} icon="CheckCircle">
        Approve as a Client
      </MenuItemButton>
    );
  },
};
