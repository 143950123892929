import { ThemeAttributes } from "types/theme";

//till versions API is not implemented - we use these mock data
export const MOCKED_VERSIONS = [
  { label: "1.2 (Draft)", value: "1.2" },
  { label: "1.1", value: "1.1" },
  { label: "1.0", value: "1.0" },
];

export const THEME_MOCK_DATA: ThemeAttributes = {
  id: "string",
  status: "DRAFT",
  version: "string",
  versionNotes: "string",
  draftedFromVersion: "string",
  name: "string",
  description: "string",
  typekitId: "string",
  publishedAt: "2024-10-28T17:22:53.951Z",
  lastModifiedDate: "2024-10-28T17:22:53.951Z",
  createdDate: "2024-10-28T17:22:53.951Z",
  rootThemeId: "string",
  //other data
  client: {
    id: "string123",
    name: "string",
    clientCode: "0kp5",
    collegeUrl: "string",
    privacyPolicyUrl: "string",
    primaryContact: {
      id: 12,
      name: "string",
      email: "string",
      jobTitle: "string",
    },
    iterableProjectId: "string",
    apolloDomainUrl: "string",
    iterableApiKey: "string",
    tealiumProfileCode: "string",
  },
  lockedBy: {
    id: "string",
    firstName: "string",
    lastName: "string",
    email: "string",
  },
  typekitFonts: [
    {
      id: "string",
      name: "string",
      defaultFontWeight: "string",
      boldFontWeight: "string",
      fallbackFont: "string",
      slug: "string",
      css_names: ["string"],
      css_stack: "string",
      variations: ["string"],
    },
  ],
  colors: {
    brand: {
      primary: "color",
      secondary: "color",
      tertiary: "color",
    },
    neutrals: {
      intense: "color",
      bold: "color",
      soft: "color",
      white: "color",
    },
  },
  fonts: {
    googleFonts: ["url to google font", "another url to google font"],
    adobeFontsProjectId: "projectId",
  },
  typography: {
    heading: {
      fontFamily: "fontFamily",
      fallbackFontFamily: "fallbackFontFamily",
      fontSize: "fontSize",
      lineHeight: "lineHeight",
      fontWeight: "fontWeight",
      textColor: "textColor",
    },
    subHeading: {
      fontFamily: "fontFamily",
      fallbackFontFamily: "fallbackFontFamily",
      fontSize: "fontSize",
      lineHeight: "lineHeight",
      fontWeight: "fontWeight",
      textColor: "textColor",
    },
    normalBodyText: {
      fontFamily: "fontFamily",
      fallbackFontFamily: "fallbackFontFamily",
      fontSize: "fontSize",
      lineHeight: "lineHeight",
      fontWeight: "fontWeight",
      textColor: "textColor",
    },
    smallBodyText: {
      fontFamily: "fontFamily",
      fallbackFontFamily: "fallbackFontFamily",
      fontSize: "fontSize",
      lineHeight: "lineHeight",
      fontWeight: "fontWeight",
      textColor: "textColor",
    },
    textLink: {
      fontFamily: "fontFamily",
      fallbackFontFamily: "fallbackFontFamily",
      fontSize: "fontSize",
      lineHeight: "lineHeight",
      fontWeight: "fontWeight",
      textColor: "textColor",
    },
    buttonLabel: {
      fontFamily: "fontFamily",
      fallbackFontFamily: "fallbackFontFamily",
      fontSize: "fontSize",
      lineHeight: "lineHeight",
      fontWeight: "fontWeight",
      textColor: "textColor",
    },
  },
  button: {
    borderRadius: "buttonBorderRadius",
    paddingHorizontal: "paddingHorizontal",
    paddingVertical: "paddingVertical",
    bgColor: "bgColor",
    customBgColor: "", //empty if bgColor is chosen
    borderStyle: "borderStyle",
    dashedBorderStyle: {
      dashWidth: "dashWidth",
      gapWidth: "gapWidth",
      dashCap: "dashWidth",
    },
    boxShadow: "boxShadow",
    customBoxShadow: {
      //customBoxShadow is null if boxShadow is defined
      xLine: "0",
      yLine: "4",
      blur: "4",
      spread: "0",
      opacity: "20", //in percentage
    },
  },
};
