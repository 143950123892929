import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Touchpoint, { TouchpointAttributes } from "models/touchpoint";
import { TouchpointVersionAttributes, TouchpointVersionsType } from "models/touchpoint-version";

import { emptyPaginatedResponse, PaginatedResponse } from "types/pagination";

interface TouchpointsSlice {
  current: TouchpointAttributes | null;
  versions: PaginatedResponse<TouchpointVersionAttributes>;
  versionsDictionary: TouchpointVersionsType[];
}

const initialState: TouchpointsSlice = {
  current: null,
  versions: emptyPaginatedResponse,
  versionsDictionary: [],
};

export const touchpointsSlice = createSlice({
  name: "touchpoints",
  initialState,
  reducers: {
    setCurrentTouchpoint: (state, action: PayloadAction<TouchpointAttributes | null>) => {
      state.current = action.payload;
    },
    setTouchpointVersions: (
      state,
      action: PayloadAction<PaginatedResponse<TouchpointVersionAttributes>>,
    ) => {
      state.versions = action.payload;
    },
    setTouchpointVersionsDictionary: (state, action: PayloadAction<TouchpointVersionsType[]>) => {
      state.versionsDictionary = action.payload;
    },
  },
});

export const currentTouchpointSelector = (state: { touchpoints: TouchpointsSlice }) =>
  state.touchpoints.current;

export const touchpointVersionsSelector = (state: { touchpoints: TouchpointsSlice }) =>
  state.touchpoints.versions;

export const touchpointVersionsDictionarySelector = (state: { touchpoints: TouchpointsSlice }) =>
  state.touchpoints.versionsDictionary;

export const { setCurrentTouchpoint, setTouchpointVersions, setTouchpointVersionsDictionary } =
  touchpointsSlice.actions;
export default touchpointsSlice.reducer;

export function useCurrentTouchpoint(): Touchpoint {
  const attributes = useSelector(currentTouchpointSelector);
  if (!attributes) {
    throw new Error("useCurrentTouchpoint: called before a touchpoint was stored in redux");
  }

  return useMemo(() => {
    return new Touchpoint(attributes);
  }, [attributes]);
}

export type { TouchpointsSlice };
