export enum Route {
  dashboard = "/dashboard",
  clients = "/clients",
  inbox = "/inbox",
  reviews = "/reviews",
  approved = "/approved",
  admin = "/admin",
  new = "/new",
  edit = "/edit",
  campaigns = "/campaigns",
  settings = "/settings",
  creative = "/creative",
  profile = "/profile",
  assets = "/assets",
  theme = "/theme",
  notFound = "/404",
  uiKit = "/ui-kit",
  emails = "/emails",
  landingPages = "/landing-pages",
  emptyJourneyNodes = "/empty-journey-nodes",
  emailBuilder = "/email-builder",
  emailPreview = "/email-preview",
  lpBuilder = "/landing-page-builder",
  lpPreview = "/landing-page-preview",
  login = "/login",
  logout = "/logout",
  versions = "/versions",
  themeBuilder = "/theme-builder",
  themePreview = "/theme-preview",
  details = "/details",
  preview = "/preview",
  basic = "/basic",
  team = "/team-members",
  emailTypes = "/email-types",
  design = "/design",
}

export enum PreviewRoute {
  color = "/color",
  fonts = "/fonts",
  typography = "/typography",
  button = "/button",
}

/**
 * Constructs a URL path from given segments, handling mixed inputs with or without leading slashes.
 *
 * @param segments - An array of string segments to be joined into a path.
 * @returns A string representing the constructed URL path, always starting with a '/'.
 *
 * @example
 * constructPath('/clients', '123', '/campaigns', '456', '/creative')  // returns '/clients/123/campaigns/456/creative'
 * constructPath(Route.clients, '123', Route.campaigns, '456', Route.creative)  // returns '/clients/123/campaigns/456/creative'
 */
export const constructPath = (...segments: string[]): string => {
  return (
    "/" +
    segments
      .map((segment) => segment.replace(/^\//, "")) // Remove leading slash if present
      .filter(Boolean) // Remove empty segments
      .join("/")
  );
};
