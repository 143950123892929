import { Route as AppRoute, PreviewRoute } from "utilities/app-routes";

export const getThemeTabRoute = (str: string, url: string) => {
  switch (str) {
    case "Details":
      return url + AppRoute.details;
    case "Version History":
      return url + AppRoute.versions;
    default:
      return url + AppRoute.preview + PreviewRoute.color;
  }
};

export const getThemePreviewTabRoute = (str: string, url: string) => {
  switch (str) {
    case "Fonts":
      return url + PreviewRoute.fonts;
    case "Typography":
      return url + PreviewRoute.typography;
    case "Button":
      return url + PreviewRoute.button;
    default:
      return url + PreviewRoute.color;
  }
};
