import { useEffect, useState } from "react";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { ApolloSelect } from "@marketview/lunar-components";

import { RouteMatchTypes } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import ThemeRoutes from "containers/admin/clients/client/tab-components/themes-tab/theme/theme-routes";

import { H2 } from "components/partials/typography/typography";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";

import { setCurrentTheme } from "state/ducks/themes";
import { useCurrentClient } from "state/ducks";

import { getThemeTabRoute } from "containers/admin/clients/client/tab-components/themes-tab/theme/theme.helper";

import { THEME_MOCK_DATA, MOCKED_VERSIONS } from "./theme-mock-data";

const ThemePage = ({ baseThemeUrl }: { baseThemeUrl: string }) => {
  const { params, url }: RouteMatchTypes = useRouteMatch();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [currentTab, setCurrentTab] = useState<string>("Preview");

  const currentClient = useCurrentClient();

  const tabs = [
    { name: "Preview", path: "preview" },
    { name: "Version History", path: "versions" },
  ];

  const getTabFromPath = () => {
    const path = location.pathname;
    const matchedTab = tabs.find((tab) => path.includes(tab.path))?.name;
    return matchedTab || "Preview";
  };

  useEffect(() => {
    const activeTab = getTabFromPath();
    setCurrentTab(activeTab);
    // eslint-disable-next-line
  }, [location.pathname]);

  const changeTab = (str: string) => {
    const tab = getThemeTabRoute(str, url);
    history.push(tab);
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentTheme(THEME_MOCK_DATA));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box backgroundColor="gray.200" pt={1} mx={-8} px={8} mb={4}>
        <Breadcrumbs
          breadcrumbs={[
            { label: "Clients", href: "/clients" },
            { label: currentClient?.name, href: `/clients/${params?.clientId}/campaigns` },
            { label: "Theme Configuration", isActive: true },
          ]}
        />
        <div className="flex justify-between my-3">
          <H2>Theme Configuration</H2>
          <div className="w-56">
            <ApolloSelect
              ariaLabel="Select a theme version"
              name="theme-versions-select"
              size="sm"
              placeholder="Lunar Select here!"
              defaultValue={MOCKED_VERSIONS[0]}
              options={MOCKED_VERSIONS}
              onChange={() => {
                currentTab !== "Preview" && changeTab("Preview");
              }}
            />
          </div>
        </div>
        <Box>
          <ToggleButtonGroup
            variant="primary"
            groupName="theme-tabs-toggle"
            options={["Preview", "Version History"]}
            onChange={(option) => changeTab(option)}
            value={currentTab}
          />
        </Box>
      </Box>
      <ThemeRoutes />
    </>
  );
};

export default ThemePage;
