import React from "react";
import { twMerge } from "tailwind-merge";
import Icons, { IconName } from "assets";

export interface MenuItemButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * @tutorial If icon is not showing, check if the source SVG uses clipPath and try removing it.
   * ```
   * // if this isn't displaying
   * <clipPath id="clip0_3616_25193">
        <rect width="24" height="24" fill="white" />
    </clipPath>

    // trying changing it to
    <rect width="24" height="24" fill="white" />
    ```
   */
  icon?: IconName;
  /**
   * Any callback function to fire after `onClick`
   * - @example `onAction={() => onCloseMenu()}`
   */
  onAction?: () => void;
}

const baseClassNames = `
  flex items-center py-2 px-3 w-full rounded-md
  text-base text-neutral-2 
  hover:bg-green-2 hover:text-neutral-1
`;

const MenuItemButton = ({
  children,
  className,
  icon,
  onClick,
  onAction,
  ...rest
}: MenuItemButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event); // Primary button logic
    }
    if (onAction) {
      onAction(); // Secondary button logic
    }
  };

  return (
    <button className={twMerge(baseClassNames, className)} onClick={handleClick} {...rest}>
      {icon && React.createElement(Icons[icon], { className: "mr-2 flex-shrink-0 w-5 h-5" })}
      <span className="flex-grow text-left">{children}</span>
    </button>
  );
};

export default MenuItemButton;
